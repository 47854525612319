import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { StorageService } from "./storage.service";
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(
    private StorageService : StorageService,
    private Router: Router,
    private Storage : Storage,
  ) {}
  async canLoad(): Promise<boolean> {
    // return true;
    const currentuser = await this.Storage.get('token')
    const Status = await this.Storage.get('Status');
    if(currentuser != '' && currentuser != undefined && currentuser != null){
      if (Status == true) {
        return true;
      }else{
        this.Router.navigate(['/auth']);
        return false;
      }
    }else{
      this.Router.navigate(['/auth']);
      return false;
    }

  }
}
